<template>
  <div>
    <a href="#" @click.prevent="open_finder()">
      <img
        v-if="avatar_url && avatar_url.length > 0"
        :src="'data:image/png;base64,' + avatar_url" :class="apply_class" id="eldropzone">
      <img v-else-if="!avatar_url && image_previo"
        :class="apply_class" src="@/assets/images/profile.svg" alt="image" id="eldropzone">
      <div v-else class="upload-file-content" id="eldropzone">
        <div class="upload-file">Click para subir archivo</div>
      </div>
    </a>
    <!--Hidden input file -->
    <!--make change pressentation-->
    <input v-if="image_previo"
      id="files"
      type="file"
      style="visibility: hidden;"
      accept="image/*"
      @change="SeletedFile()"
    >
    <input v-else
      id="files"
      type="file"
      style="visibility: hidden;"
      accept="application/pdf"
      @change="SeletedFile()"
    >
  </div>
</template>

<script>

export default {
  name: 'Upload',
  created() {
    this.$nextTick(() => {
      this.add_event_drop_ele();
    });
  },
  data() {
    return {
      instance: {},
    };
  },
  props: {
    apply_class: {
      type: String,
      default: null,
    },
    avatar_url: {
      type: String,
      default: null,
    },
    avatar_id: {
      type: String,
      default: null,
    },
    image_previo: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    async add_event_drop_ele() {
      this.$nextTick(() => {
        const dropZoneDiv = document.querySelector('#eldropzone');

        dropZoneDiv.ondragover = (event) => {
          dropZoneDiv.classList.add('dropzone-hover');
          event.stopPropagation();
          event.preventDefault();
          /* eslint no-param-reassign: [2, { "props": false }] */
          event.dataTransfer.dropEffect = 'copy';
        };
        dropZoneDiv.ondragleave = () => {
          dropZoneDiv.classList.remove('dropzone-hover');
        };
        // Handle file drop:
        dropZoneDiv.ondrop = async (ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          dropZoneDiv.classList.remove('dropzone-hover');
          const { files } = ev.dataTransfer;
          this.SeletedFile(files);
        };
      });
      // Configure dropZoneDiv
    },
    validar_archivos(files) {
      const tipos = ['video/x-msvideo', 'video/mp4'];
      /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
      for (let file = 0; file < files.length; file++) {
        if (files[file].size === 0) {
          this.$swal.fire('Error!', `El archivo  ${files[file].name}  esta vacio`, 'error');
          return false;
        }
        if (files[file].size > 5000000) {
          this.$swal.fire('Error!', `El archivo ${files[file].name} supera el límite permitido`, 'error');
          return false;
        }
        if (tipos.includes(files[file].type)) {
          this.$swal.fire('Error!', `El archivo ${files[file].name} tiene un formato no permitido.`, 'error');
          return false;
        }
        const videoType = /video.*/;
        if (files[file].type.match(videoType)) {
          this.$swal.fire('Error!', `El archivo ${files[file].name} tiene un formato no permitido.`, 'error');
          return false;
        }
      }
      return true;
    },
    emit_data() {
      this.$emit('get_id_avatar', this.instance.id);
    },
    handlerError(error) {
      if (typeof (error.response.data) === 'object') {
        const result = error.response.data;
        Object.entries(result).forEach(([key, value]) => {
          this.$swal.fire('Error!', `${key} : ${value}`, 'error');
        });
        return false;
      }
      this.$swal.fire('Error!', error.response.data, 'error');
      return true;
    },
    async postFileRequest(payload) {
      const data = await this.$http.post(
        '/pictures/',
        payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    },
    async putFileRequest(payload) {
      const data = await this.$http.put(
        `/pictures/${this.avatar_id}/`,
        payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    },
    SeletedFile(files = null) {
      let imagefile = files;
      if (files === null) {
        const data = document.querySelector('#files');
        imagefile = data.files;
      }

      const result = this.validar_archivos(imagefile);
      if (!result) return;
      const formData = new FormData();
      formData.append('url', imagefile[0]);
      if (this.avatar_id) {
        formData.append('id', this.avatar_id);
        this.update_logo(formData);
        return;
      }
      this.create_logo(formData);
    },
    open_finder() {
      document.querySelector('#files').click();
    },
    update_logo(payload) {
      const self = this;
      this.putFileRequest(payload).then((data) => {
        self.instance.id = data.data.id;
        self.emit_data();
      }).catch((error) => {
        this.handlerError(error);
      });
    },
    create_logo(payload) {
      const self = this;
      this.postFileRequest(payload).then((data) => {
        self.instance.id = data.data.id;
        self.emit_data();
      }).catch((error) => {
        console.log(error);
      });
    },
  },
};
</script>
<style scope>
  .null {
    text-decoration: line-through !important;
  }
  .upload-file {
    border-style: dotted;
    border-style-color: red !important;
  }
  .dropzone {
    border: 2px dotted #bbb;
    border-radius: 10px;
    padding: 5px;
    color: #bbb;
    text-align: center;
    height: auto;
  }
  .dropzone-hover {
    border: 4px dotted #bbb !important;
    color: #bbb0;
  }
  @media screen(max-width: 900px) {
    .dropzone {
      padding: 10px !important;
    }
  }
  @media screen(max-width: 600px) {
    .dropzone {
      padding: 10px !important;
      font-size: 10px;
    }
  }

</style>
